import { RouteRecordRaw } from "vue-router";

const pricesRoutes: RouteRecordRaw[] = [
    {
        path: 'prices',
        name: 'prices-list',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/prices/view/PricesList.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Prices",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'prices/create',
        name: 'create-price',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/prices/view/CreatePrice.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Prices",
                    redirect: "prices-list",
                    disabled: false,
                },
                {
                    name: "Create",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'prices/edit/:priceId',
        name: 'edit-price',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/prices/view/EditPrice.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Prices",
                    redirect: "prices-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    }
]


export default pricesRoutes