import { RouteRecordRaw } from "vue-router";
import parkingSpotsRoutes from "./parkingSpotsRoutes";

const parkingLotsRoutes: RouteRecordRaw[] = [
    {
        path: 'parking-lots',
        name: 'lots-list',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/parkingLots/views/ParkingLotsList.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Parking Lots",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'parking-lots/create',
        name: 'create-lot',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/parkingLots/views/CreateParkingLot.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Parking Lots",
                    redirect: "lots-list",
                    disabled: false,
                },
                {
                    name: "Create",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'parking-lot/edit/:lotId',
        name: 'edit-lot',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/parkingLots/views/EditParkingLot.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Parking Lots",
                    redirect: "lots-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
        children: [
            ...parkingSpotsRoutes
        ]
    },
]


export default parkingLotsRoutes