import { RouteRecordRaw } from "vue-router";

const propertyUnitsRoutes: RouteRecordRaw[] = [
    {
        path: 'units',
        name: 'unit-list',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyUnits/views/web/PropertyUnitsWeb.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Units",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'units/create',
        name: 'create-unit',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyUnits/views/CreateUnit.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Units",
                    redirect: "unit-list",
                    disabled: false,
                },
                {
                    name: "Create",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'units/create',
        name: 'create-single-unit',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyUnits/views/SingleAddress.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Units",
                    redirect: "unit-list",
                    disabled: false,
                },
                {
                    name: "Create",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'units/edit/:unitId',
        name: 'edit-unit',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyUnits/views/EditUnit.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Units",
                    redirect: "unit-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'units/edit/:unitId',
        name: 'edit-single-unit',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyUnits/views/EditSingleUnit.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Units",
                    redirect: "unit-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    }
]


export default propertyUnitsRoutes