import { RouteRecordRaw } from "vue-router";
import assignedUnitsRoutes from "./assignedUnitsRoutes";
import residentParkingRoutes from "./residentParkingSpotsRoutes";

const propertyResidentRoutes: RouteRecordRaw[] = [
    {
        path: 'residents',
        name: 'resident-list',
        component: () => import( /* webpackChunkName: "property-residents" */ "../modules/admin/submodules/properties/propertyResidents/views/web/PropertyResidents.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Properties", redirect: "properties-list", disabled: false },
                { name: "Edit", redirect: "", disabled: true },
                { name: "Residents", redirect: "", disabled: true }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'resident/create',
        name: 'create-resident',
        component: () => import( /* webpackChunkName: "create-resident" */ "../modules/admin/submodules/properties/propertyResidents/views/web/CreateResident.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Properties", redirect: "properties-list", disabled: false },
                { name: "Edit", redirect: "", disabled: true },
                { name: "Residents", redirect: "resident-list", disabled: false },
                { name: "Create", redirect: "", disabled: true }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'residents/edit/:residentId',
        name: 'edit-resident',
        component: () => import( /* webpackChunkName: "edit-resident" */ "../modules/admin/submodules/properties/propertyResidents/views/web/EditResidentContainer.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                { name: "Properties", redirect: "properties-list", disabled: false },
                { name: "Edit", redirect: "", disabled: true },
                { name: "Residents", redirect: "resident-list", disabled: false },
                { name: "Edit", redirect: "", disabled: true }
            ],
            parent: "properties-list",
        },
        children: [
            ...assignedUnitsRoutes,
            ...residentParkingRoutes
        ]
    }
];

export default propertyResidentRoutes;