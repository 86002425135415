import { RouteRecordRaw } from "vue-router";

const userRoutes: RouteRecordRaw[] = [
  {
    path: "users",
    name: "users",
    component: () =>
      import(
        /* webpackChunkName: "users layout" */ "../modules/admin/submodules/users/layout/UserLayout.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: 'redirect',
        redirect: { name: "users-list" }, // Redirige a `company-list` cuando se accede a `companies` sin una ruta específica
      },
      {
        path: 'list',
        name: 'users-list',
        component: () => import( /* webpackChunkName: "users list" */ "../modules/admin/submodules/users/views/UserList.vue"),
        meta: {
          requiresAuth: true,
          breadcrumbs: [
            {
              name: "Users",
              redirect: "users-list",
              disabled: false,
            }
          ],
          parent: "users-list",
        },
      },
      {
        path: 'create',
        name: 'create-invitation',
        component: () => import( /* webpackChunkName: "create invitation" */ "../modules/admin/submodules/users/views/CreateUserInvitation.vue"),
        meta: {
          requiresAuth: true,
          breadcrumbs: [
            {
              name: "Users",
              redirect: "users-list",
              disabled: false,
            },
            {
              name: "Create Invitation",
              redirect: "create-invitation",
              disabled: true,
            }
          ],
          parent: "users-list",
        },
      }
    ]
  },
];

export default userRoutes;
