import { RouteRecordRaw } from "vue-router";

const residentParkingRoutes: RouteRecordRaw[] = [
    {
        path: 'residentParkingSpots',
        name: 'resident-spots',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyResidents/views/web/AssingSpot.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Residents",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
]


export default residentParkingRoutes