import { RouteRecordRaw } from "vue-router";

const parkingSpotsRoutes: RouteRecordRaw[] = [
    {
        path: 'parking-spots',
        name: 'spots-list',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/parkingSpots/views/ParkingSpots.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Parking Lots",
                    redirect: "lots-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Parking Spots",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'parking-spots/create',
        name: 'create-spot',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/parkingSpots/views/CreateParkingSpot.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Parking Lots",
                    redirect: "lots-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Parking Spots",
                    redirect: "spots-list",
                    disabled: false,
                },
                {
                    name: "Create",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'parking-lot/edit/:spotId',
        name: 'edit-spot',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/parkingSpots/views/EditParkingSpot.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Parking Lots",
                    redirect: "lots-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Parking Spots",
                    redirect: "spots-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    }
]


export default parkingSpotsRoutes;