import { RouteRecordRaw } from "vue-router";

const propertyAddressRoutes: RouteRecordRaw[] = [
    {
        path: 'address',
        name: 'address-list',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyAddress/views/PropertyAddressList.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Address",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'address/create',
        name: 'create-address',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyAddress/views/CreateAddress.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Address",
                    redirect: "address-list",
                    disabled: false,
                },
                {
                    name: "Create",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'address/edit/:propertyAddressId',
        name: 'edit-address',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyAddress/views/EditAddress.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs:  [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Address",
                    redirect: "address-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    }
]


export default propertyAddressRoutes