import { RouteRecordRaw } from "vue-router";

const assignedUnitsRoutes: RouteRecordRaw[] = [
    {
        path: 'assigned-units',
        name: 'units-assigned-list',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyResidents/views/web/UnitsList.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Residents",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'assigned-units/create',
        name: 'assign-unit',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyResidents/views/web/AssignUnit.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Residents",
                    redirect: "resident-list",
                    disabled: false,
                },
                {
                    name: "Create",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    },
    {
        path: 'assigned-units/:assignUnitId',
        name: 'edit-assigned-unit',
        component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/propertyResidents/views/web/EditAssignedUnit.vue"),
        meta: {
            requiresAuth: true,
            breadcrumbs: [
                {
                    name: "Properties",
                    redirect: "properties-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                },
                {
                    name: "Residents",
                    redirect: "resident-list",
                    disabled: false,
                },
                {
                    name: "Edit",
                    redirect: "",
                    disabled: true,
                }
            ],
            parent: "properties-list",
        },
    }
]


export default assignedUnitsRoutes;