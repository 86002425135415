import { RouteRecordRaw } from "vue-router";
import pricesRoutes from "./pricesRoutes";
import propertyAddressRoutes from "./propertyAddressRoutes";
import propertyUnitsRoutes from "./propertyUnitsRoutes";
import parkingLotsRoutes from "./propertyParkingLotsRoutes";
import propertyResidentRoutes from "./propertyResidentsRoutes";

const propertyRoutes: RouteRecordRaw[] = [
    {
        path: "properties",
        name: "properties",
        component: () =>
            import(
            /* webpackChunkName: "properties layout" */ "../modules/admin/submodules/properties/layout/PropertiesLayout.vue"
            ),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "",
                name: 'redirect',
                redirect: { name: "properties-list" },
            },
            {
                path: 'list',
                name: 'properties-list',
                component: () => import( /* webpackChunkName: "properties list" */ "../modules/admin/submodules/properties/views/PropertiesList.vue"),
                meta: {
                    requiresAuth: true,
                    breadcrumbs: [
                        {
                            name: "Properties",
                            redirect: "properties-list",
                            disabled: false,
                        }
                    ],
                    parent: "properties-list",
                },
            },
            {
                path: 'create',
                name: 'create-property',
                component: () => import( /* webpackChunkName: "create property" */ "../modules/admin/submodules/properties/views/CreateProperty.vue"),
                meta: {
                    requiresAuth: true,
                    breadcrumbs: [
                        {
                            name: "Properties",
                            redirect: "properties-list",
                            disabled: false,
                        },
                        {
                            name: "Add",
                            redirect: "",
                            disabled: true,
                        }
                    ],
                    parent: "properties-list",
                },
            },
            {
                path: 'edit/:propertyId',
                name: 'edit-property',
                component: () => import( /* webpackChunkName: "edit property" */ "../modules/admin/submodules/properties/views/EditProperty.vue"),
                meta: {
                    requiresAuth: true,
                    breadcrumbs: [
                        {
                            name: "Properties",
                            redirect: "properties-list",
                            disabled: false,
                        },
                        {
                            name: "Edit",
                            redirect: "",
                            disabled: true,
                        }
                    ],
                    parent: "properties-list",
                },
                children: [
                    ...propertyAddressRoutes,
                    ...propertyUnitsRoutes,
                    ...parkingLotsRoutes,
                    ...propertyResidentRoutes,
                    ...pricesRoutes
                ]
            },            
        ]
    }
]


export default propertyRoutes